/* .home {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
  } */
  
  /* .home .content {
    display: flex;
    justify-content: center;
    margin-bottom: 64px;
  } */
  
  /* .home .content h3,
  h1,
  h2 {
    color: #fff;
  } */

  .home__container{
    row-gap: 7rem;
  }

  .home__content{
    grid-template-columns: 116px repeat(2,1fr);
    padding-top: 8rem;
    column-gap: 2rem;
    align-items: center;
  }
  
  .home__social{
    display: grid;
    grid-template-columns: max-content;
    row-gap: 1rem;
  }

  .home__social-icon{
    font-size: 1.25rem;
    color:#f0f5f9 ;
  }

  .home__social-icon:hover{
    color: aqua;
  }
  .hero-img-wrap {
    /* will-change: opacity;
    animation: heroImgWrap 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.8s; */
    position: relative;
    z-index: 2;
    width: 275px;
    height: 275px;
    max-height: 30vh;
    max-width: 30vh;
    min-height: 275px;
    min-width: 275px;
    /* margin-bottom: 64px; */
    margin-right: 64px;
    /* display: block; */
  }
  
  .hero-img {
    /* will-change: transform;
    animation: heroImgScale 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.8s; */
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .hero-img-container {

    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 12px;
    border: 1px solid hsla(0, 0%, 100%, 0.3);
    border-radius: 50%;
    background-color: hsla(0, 0%, 84%, 0.5);
    -o-object-fit: cover;
    object-fit: cover;
    
  }
  
  .hero-img-shadow-wrap {
    position: absolute;
    left: -20%;
    top: -20%;
    right: 0;
    bottom: 0;
    width: 140%;
    height: 140%;
    padding: 20%;
    border-radius: 50%;
    /* -webkit-filter: blur(20px); */
    filter: blur(20px);
  }
  
  /* .hero-img-shadow-wrap.hero-img-shadow-wrap--intro {
    z-index: 1;
  } */
  
  .hero-img-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* background-image: radial-gradient(
        circle farthest-side at 100% 100%,
        #9873ff,
        rgba(152, 115, 255, 0)
      ),
      radial-gradient(
        circle farthest-side at 100% 0,
        #0ba5f7,
        rgba(85, 196, 255, 0)
      ),
      radial-gradient(circle farthest-side at 0 0, #ff763c, rgba(255, 176, 60, 0)),
      radial-gradient(
        circle farthest-side at 0 100%,
        #ff5aaa,
        rgba(255, 90, 170, 0)
      ); */
    background-image: radial-gradient(
        circle farthest-side at 100% 100%,
        #833ab4,
        rgba(176, 115, 255, 0)
      ),
      radial-gradient(
        circle farthest-side at 100% 0,
        #fd1d1d,
        rgba(255, 85, 85, 0)
      ),
      radial-gradient(circle farthest-side at 0 0, #ff763c, rgba(255, 176, 60, 0)),
      radial-gradient(
        circle farthest-side at 0 100%,
        #fcb045,
        rgba(255, 197, 90, 0)
      );
  }
  
  #hero-img-shadow-1 {
    will-change: opacity;
    animation: heroImgShadow1 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 1.5s;
  }
  @keyframes heroImgShadow1 {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  #hero-img-shadow-2 {
    will-change: opacity, transform;
    animation: heroImgShadow2 1.2s cubic-bezier(0.65, 0, 0.35, 1);
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-delay: 1.5s;
  }
  
  @keyframes heroImgShadow2 {
    0% {
      opacity: 1;
      transform: scale(1, 1);
    }
    99% {
      opacity: 0;
      transform: scale(6, 6);
      height: 140%;
    }
    100% {
      height: 0%;
      opacity: 0;
    }
  }

  .home__title{
    font-size: 2.6rem;
    margin-bottom: .25rem;
  }

  .home__subtitle{
    position: relative;
    font-size: .87rem;
    padding-right: 5.4rem;
    font-weight:400 ;
    margin-bottom: 3rem;
  }
  
  /* .hero-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 44px;
  }*/
  
   .home-description h3 {
    font-size: 1.5rem;
    text-transform: lowercase;
    /* margin-left: -394px; */
    margin-bottom: 10px;
    font-weight: 300;
  } 
  
  /* .hero-description h1 {
    font-size: 50px;
    margin-bottom: 10px;
  }*/
  
  /* .hero-description h2 {
    font-size: 18px;
    font-weight: 400;
  }  */
  

  .home__scroll{
    /* margin-left: 9.25rem ; */
    display: flex;
    justify-content: center;
  }

  .home__scroll-icon{
    color: #f0f5f9;
  }

  .wheel{
    animation: scroll 2s ease infinite;
  }

  @keyframes scroll{
    0%{
      transform: translateY(0);
    }
    30%{
      transform: translateY(2rem);
    }
  }

  .home__scroll-name{
    color: #f0f5f9;
    font-weight: 500;
    margin-right: .25rem;
    margin-left: 2px;
  }

  .home__scroll-arrow{
    font-size: 1.25rem;
    color: #f0f5f9;
  }


  @media screen and (max-width:992px) {
    .home__content{
      grid-template-columns: 100px repeat(2,1fr);
      column-gap: 1.25rem;
    }

    .home__title{
      font-size: 1.8rem;
    }

    .home__subtitle{
      font-size: .75rem;
      margin-bottom: 1rem;
    }

    .home-description h3 {
      font-size: .9rem;
    } 

    .hero-img-wrap{
      min-width: 250px;
      min-height: 250px;
    }
  }

  @media screen and (max-width:768px) {
    .home__content{
      grid-template-columns: 0.5fr 3fr;
      /* padding-top: 1.5rem;
      top: 0; */
    }
    
    .home-description{
      grid-column:1/3 ;
    }

    .hero-img-wrap{
      min-width: 200px;
      min-height: 200px;
    }

    .home__scroll{
      display: none;
    }
  }

  @media screen and (max-width:576px) {
    
  }

  @media screen and (max-width:350px) {
    .hero-img-wrap{
      min-width: 180px;
      min-height: 180px;
    }
  }