.about__container{
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    column-gap: 4rem;
}

.about__img{
    width: 350px;
    border-radius: 1.5rem;
    justify-content: center;
}

.about__info{
    grid-template-columns: repeat(2,1fr);
    gap: 0.5rem;
    margin-bottom: 2rem;
}

.about__box{
    background-color: #000;
    border: 1px solid rgba(252, 252, 252, .65);
    border-radius: .75rem;
    text-align: center;
    padding: 1rem 1.25rem;
}

.about__title{
    font-size:.875rem ;
    font-weight: 500;
}

.about__subtitle{
    font-size:.625rem ;
}

.about__description{
    padding: 0 4rem 0 0;
    margin-bottom: 2.5rem;
}



@media screen and (max-width:992px){
    .about__container{
        grid-template-columns: 1fr;
        row-gap: 2.5rem;
    }

    .about__img{
        width: 220px;
    }

    .about__box{
        padding: 0.75rem 0.5rem;
    }

    .about__data{
        text-align: center;
    }

    .about__info{
        justify-content: center;
    }

    .about__description{
        padding: 0 5rem;
        margin-bottom: 2rem;
    }
  }
  
  @media screen and (max-width:768px){
    
  }
  
  @media screen and (max-width:576px){
    
    .about__info{
        grid-template-columns: repeat(2, 1fr);
    }

    .about__description{
        padding: 0;
    }
  }
  
  @media screen and (max-width:350px){
    
    .about__info{
        grid-template-columns: repeat(2, 1fr);
    }
  }