@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'); 

:root{
  --header-height: 3rem;

  font-family: 'Poppins', sans-serif;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html{
  scroll-behavior: smooth;
}

body,button,input,textarea{
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

body{
  background-color: #1e2022;
  color: #c9d6de;
}

h1,h2,h3{
  color: #f0f5f9;
  font-weight: 600;
}

ul{
  list-style: none;
}

a{
  text-decoration: none;
}

button{
  cursor: pointer;
  border: none;
  outline: none;
}

img{
  max-width: 100%;
  height: auto;
}


.section{
  padding: 6rem 0 2rem;
}

.section__title{
  font-size: 2.25rem;
  color: #f0f5f9;
  text-align: center;
}

.section__subtitle{
  display: block;
  font-size: .875rem;
  margin-bottom:4rem ;
  text-align: center;
}

.container{
  max-width: 968px;
  margin-left: auto;
  margin-right: auto;
}

.grid{
  display: grid;
  gap: 1.5rem;
}

.button{
  display: inline-block;
  background-color: #f0f5f9;
  color: #000;
  padding: 1.25rem 2rem;
  border-radius: 1rem;
  font-weight: 500;
}

.button:hover{
  background-color: #fff;
}

.button__icon{
  margin-left: .5rem;
}

.button--flex{
  display: inline-flex;
  align-items: center;
}



@media screen and (max-width:992px){
  .container{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .button{
    padding: 1rem 1.75rem;
  }

}

@media screen and (max-width:768px){

  body{
    margin: 0 0 3rem 0;
  }
  .section{
    padding: 2rem 0 4rem;
  }

  .section__subtitle{
    margin-bottom: 3rem;
  }
}

@media screen and (max-width:576px){
  
}

@media screen and (max-width:350px){
  .container{
    margin-left: 1rem;
    margin-right: 1rem;
  }
}