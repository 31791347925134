.header{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #000;
}

.nav{
    height: calc(4.5rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 1rem;
}

.nav__logo,.nav__toggle{
    color: #f0f5f9;
    font-weight: 500;
}

.nav__list{
    display: flex;
    column-gap: 2rem;
}

.nav__links{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    transition: .3s;
}

.nav__items a{
    color: #f0f5f9;
}

.nav__icon,.nav__close, .nav__toggle{
 display: none;
}

.nav__links:hover{
    color: aqua;
}



@media screen and (max-width:768px) {
    /* .header{
        top: initial;
        top: 0;
    } */

    .nav{
        height: auto;
        padding: 1rem;
    }

    /* .nav__menu{
        position:fixed;
        top: 32px;
        left: 0;
        width: 100%;
        background-color: #000;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 1.5rem 1.5rem ;
        transition: .3s;
    } */

    .nav__menu{
        position:absolute;
        top: 0;
        left: 100%;
        width: 100%;
        background-color: #000;
        padding: 2rem 1.5rem 4rem;
        box-shadow: 0 -1px 4px rgba(0, 0, 0, 0.15);
        border-radius: 0 0 1.5rem 1.5rem ;
        transition: .3s;
    } 

    .nav__menu__active{
        left: 0;
    }
    .nav__list{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 2rem;
    }

    .nav__icon{
        font-size: 1.2rem;
    }

    .nav__close{
        position: absolute;
        right: 1.3rem;
        bottom: .5rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: #f0f5f9;
    }

    .nav__close:hover{
        color: aqua;
    }

    .nav__toggle{
        font-size: 1.1rem;
        cursor: pointer;
    }

    .nav__icon,.nav__close,.nav__toggle{
        display: block;
    }
}

@media screen and (max-width:350px){
    .nav__menu{
        padding: 2rem o.25rem 4rem;
    }

    .nav__list{
        column-gap: 0;
    }
    
}